import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet'

const ContactUsPage = ( {data} ) => {
    const contactPageData = data.allPrismicContactUs.nodes[0].data
    const title = contactPageData.title.text
    const pageContent = contactPageData.contact_page_content.html

    return (
        
      <Layout>

        <Helmet>
          <title>Contact</title>
          <meta name='description' content='Contact Monarch People' />
        </Helmet>
        
        <div className="page-content rich-text-page">
          <title>{title}</title>
          <h1>{title}</h1>

          <div>{parse(pageContent)}</div>
        </div>
        
      </Layout>
    )
  }

export default ContactUsPage

export const query = graphql`
  query {
    allPrismicContactUs {
      nodes {
        data {
          title {
            text
          }
          contact_page_content {
            html
          }
        }
      }
    }
  }
`

// export const query = graphql`
//   query {
//     prismicContactUs {
//       data {
//         title {
//           text
//         }
//       }
//     }
//     allPrismicContactUs {
//       nodes {
//         data {
//           contact_page_content {
//             html
//           }
//         }
//       }
//     }
//   }
// `